import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TouchableOpacity, Dimensions, FlatList } from "react-native"
import { Screen, Text, Header, Menu } from "../../components"
import { useNavigation, useIsFocused } from "@react-navigation/native"
import { useStores } from "../../models"
import { color } from "../../theme"
import SideMenu from 'react-native-side-menu-updated'


const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  justifyContent:"center",
  alignItems:"center",
  width: "100%",
}
const ROOT1: ViewStyle = {
  // backgroundColor: color.palette.black,
  flex: 1,
  width: "100%",
  maxWidth: 1200
}
const ITEM: ViewStyle = {
  backgroundColor: color.palette.lightBlue,
  padding: 20,
  marginVertical: 8,
  marginHorizontal: 16,
  borderRadius: 5
}




export const HomeScreen = observer(function HomeScreen(props) {
  const { userStore, navStore } = useStores()
  const { user, custServRep } = userStore
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const [data,setData] = useState([])
  const navigation = useNavigation()

  const { canGoBack } =navStore;
  const isFocused = useIsFocused();
 
  useEffect(()=>{
    if(isFocused){
      navStore.setCanGoBack(navigation.canGoBack())
      userStore.setUser(userStore.getUser())
    }

  },[isFocused])

  useEffect(()=>{
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }
  },[])

  useEffect(()=>{
    console.log(user)
  },[])

  const handlePress = (nav) =>{
      navigation.navigate(nav)
    } 


  const isCust = ()=>{

    if(custServRep){
    setData([
      {
        id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
        title: 'Chats',
        nav: 'chatList',
      },
      {
        id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
        title: 'Robot Confirmation',
        nav: 'robotConfirmation',
      }]
      )
    
    } else{
      setData(
        [{
          id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
          title: 'Chat',
          nav: 'userChat',
        }]
        )
      
    }
  }
  useEffect(()=>{
    isCust()
  },[])

  useEffect(()=>{
    isCust()
  },[custServRep])

  const Item = ({ title, nav }) => (
    <View style={ITEM}>
      <TouchableOpacity onPress={()=>{handlePress(nav)}}>
        <Text style={{fontSize:25, textAlign:"center"}}>{title}</Text>
      </TouchableOpacity>
    </View>
  );

  const renderItem = ({ item }) => (
      <Item title={item.title} nav={item.nav} />
    );
  


  
  return (

    <SideMenu menu=<Menu navigation={navigation}/> user={user} setUser={()=>{userStore.setUser("")}} onChange={(e)=>{setOpen(e)}}  isOpen={open} menuPosition="right" openMenuOffset={width}> 
      <Screen style={ROOT} preset="fixed">
        <View style={ROOT1} >
          <View >
            <Header
              openMenu={()=>{setOpen(prev=>!prev)}}
              navigation={navigation}
              canGoBack={canGoBack}

            />  
          </View>
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={item => item.id}
          />
        </View>
      </Screen>
    </SideMenu>
  )
})
