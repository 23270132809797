import React, { useEffect, useState } from "react"
import { StyleProp, TextStyle, View, ViewStyle, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { color, typography } from "../../theme"
import { Text,Screen } from "../"
import { flatten } from "ramda"
// import { getAuth, signOut } from "firebase/auth";
// import { navigationRef, RootNavigation } from "./navigation-utilities"
import { CommonActions } from "@react-navigation/native"
import firebase from "../../components/firebase/firebase"
import { useStores } from "../../models"






const CONTAINER: ViewStyle = {
  paddingTop:30,
  justifyContent: "flex-start",
  flex:1,
  backgroundColor:"#a6a6a6",
}

const TEXT: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 20,
  color: "black",
}

export interface MenuProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>


}

/**
 * Describe your component here
 */
export const Menu = observer(function Menu(props: MenuProps) {
  const { style, navigation } = props
  const styles = flatten([CONTAINER, style])
  const { userStore } = useStores()
  const { user, custServRep } = userStore;

  // const auth = getAuth()

  const handlePress = (nav) =>{
      navigation.navigate(nav)
    } 
  const handleLogout = () =>{
        // alert(user, 'user')
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        // alert('signed out')
        userStore.setUser("")
      //   const resetAction = CommonActions.reset({
      //     index: 0,
      //     routes: [{
      //         name: "login"
      //       }]
      //   });
      // navigation.dispatch(resetAction)
        }).catch((error) => {
        // An error happened.
        alert(error)
      });
    } 

    // useEffect(()=>{
    //   console.log(user)
    // },[user])


    return (
      <Screen style={CONTAINER} >
        <Text text=""/>
        <Text text=""/>
        
        <View >
        <TouchableOpacity style={{padding: 10}} onPress={()=>{handleLogout()}}>
          <Text style={TEXT} >Logout</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>{handlePress("home")}} style={{padding: 10}}>
          <Text style={TEXT}>Home</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>{handlePress("chatList")}} style={{padding: 10}}>
          <Text style={TEXT}>Chats</Text>
        </TouchableOpacity>
        { custServRep &&
          <TouchableOpacity onPress={()=>{handlePress("robotConfirmation")}} style={{padding: 10}}>
            <Text style={TEXT}>Robot Confirmation</Text>
          </TouchableOpacity>
        }
        </View>
        

      </Screen>

    )
})
