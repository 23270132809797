import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, ViewStyle, TextStyle, TouchableOpacity } from "react-native"
import { HeaderProps } from "./header.props"
import { Button } from "../button/button"
import { Text } from "../text/text"
import { Icon } from "../icon/icon"
// import { Icon as IconElement } from 'react-native-elements'
import { useStores } from "../../models"
import { navigationRef, RootNavigation } from "./navigation-utilities"





import { spacing } from "../../theme"
import { translate } from "../../i18n/"

// static styles
const ROOT: ViewStyle = {
  flexDirection: "row",
  paddingHorizontal: spacing[4],
  alignItems: "center",
  paddingTop: spacing[3],
  paddingBottom: spacing[3],
  justifyContent: "flex-start",
}
const TITLE: TextStyle = { textAlign: "center"}
const TITLE1: TextStyle = { textAlign: "center", fontSize:20}
const TITLE_MIDDLE: ViewStyle = { flex: 4, justifyContent: "center" }
const LEFT: ViewStyle = { width: 32, flex: 1, alignItems: "center", justifyContent: "center" }
const RIGHT: ViewStyle = { width: 32, flex: 1, alignItems: "center", justifyContent: "center" }

/**
 * Header that appears on many screens. Will hold navigation buttons and screen title.
 */
export const Header = observer(function Header(props: HeaderProps) {
  const {
    openMenu,
    navigation,
    canGoBack,
    focus
  } = props
  // const [ canGoBack, setCanGoBack ] =useState(true)


  // useEffect(()=>{
  //   setCanGoBack(navigation.canGoBack())
  // })

  return (
    <View style={[ROOT]}>
      { canGoBack ? (
          <TouchableOpacity style={LEFT} onPress={()=>{navigation.goBack()}}>
            <Icon icon="back"/>
          </TouchableOpacity>
      ) : (
        <View style={LEFT} />
      )}
      <View style={TITLE_MIDDLE}>
        {/*<TouchableOpacity style={{borderColor:"blue",borderWidth:2}} }>*/}
          <Text style={[TITLE]}>
          { focus == 'login' || focus =='signup' ? (
            <Text style={[TITLE1]} text="Ajent"/>
          ):(
            <Text style={[TITLE1]} text="Ajent" onPress={()=>{navigation.navigate('home')}}/>
          )}
          </Text>
        {/*</TouchableOpacity>*/}
      </View> 

        { focus == 'login' || focus =='signup' ? (
            <View style={RIGHT} />
          ):(
            <TouchableOpacity style={RIGHT} onPress={openMenu}>
              <Icon  icon="menu" />
            </TouchableOpacity>
          )}
      
    </View>
  )
})
