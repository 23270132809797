import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const LoginModel = types
  .model("Login")
  .props({
    username: types.optional(types.string,"jack"),
    password: types.optional(types.string,"jill")
  })
  .views((self) => ({
    getUsername: ()=>{
      return self.username
    },
    getPassword: (e)=>{
      return self.password
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setUsername: (e)=>{
      self.username=e
    },
    setPassword: (e)=>{
      self.password=e
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

type LoginType = Instance<typeof LoginModel>
export interface Login extends LoginType {}
type LoginSnapshotType = SnapshotOut<typeof LoginModel>
export interface LoginSnapshot extends LoginSnapshotType {}
export const createLoginDefaultModel = () => types.optional(LoginModel, {})
