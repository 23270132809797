/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React,{useState, useEffect} from "react"
import { useColorScheme, ScrollView, View } from "react-native"
import * as Notifications from "expo-notifications"
import Constants from "expo-constants"
import { NavigationContainer, DefaultTheme, DarkTheme, useNavigation, SafeAreaView, CommonActions } from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { LoginScreen, SignupScreen, HomeScreen, ChatScreen, ChatListScreen, RobotConfirmationScreen, DashboardScreen, UserChatScreen } from "../screens"
import { navigationRef, RootNavigation } from "./navigation-utilities"
import { Header } from "../components/header/header"
import firebase from "../components/firebase/firebase"
// import { getFirestore, collection, setDoc, getDocs, doc, arrayUnion, onSnapshot } from "firebase/firestore";
// import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification } from "firebase/auth";
import { useStores } from "../models"
import { color } from "../theme"







/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type NavigatorParamList = {
  welcome: undefined
  demo: undefined
  demoList: undefined
}

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<NavigatorParamList>()
interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = (props: NavigationProps) => {


  const [notification, setNotification] = useState(null)
  const [expoPushToken, setExpoPushToken] = useState(null)
  const [user, setUser] = useState("")
  const db = firebase.firestore();

  // const navigation = useNavigation()

  useEffect(()=>{
    if(expoPushToken!=null && user!=""){
     // might read the user first to see if token matches, if not update it.  reads are cheaper then writes
      db.collection("users").doc(user)
      .set({
        token: expoPushToken
      },{merge:true})
    }
  },[expoPushToken, user])

  useEffect(()=>{
    if(user!=""){
      db.collection("users").doc(user).get().then((doc) => {
          if (doc.exists) {
              const docData = doc.data()
              if(docData.customerService){
                userStore.setCustServRep(docData.customerService);
              }
          } else {
              // doc.data() will be undefined in this case
              console.log("No such document!", user);
          }
      }).catch((error) => {
          console.log("Error getting document:", error);
      });
    }
  },[user])

  const { userStore } = useStores()
  // console.log(navigationRef)
    // console.log(Constants.platform.web)

  if (Constants.isDevice && Constants.platform.web == undefined) {
    const _handleNotification = notification => {
      setNotification(notification );
    };

    const _handleNotificationResponse = response => {
      
      console.log(response.withSome);
    };

    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: false,
      }),
    });
  }

  useEffect(()=>{


  firebase.auth().onAuthStateChanged((user1) => {
    if (user1) {
      // User is signed in, see docs for a list of available properties
      console.log(Constants.platform)

      if (Constants.isDevice && Constants.platform.web == undefined && _handleNotification && _handleNotificationResponse) {
        registerForPushNotificationsAsync()
        Notifications.addNotificationReceivedListener(_handleNotification);   
        Notifications.addNotificationResponseReceivedListener(_handleNotificationResponse);
      } 
      const uid = user1.uid;
      setUser(uid);
      userStore.setUser(uid);
      const resetAction = CommonActions.reset({
          index: 0,
          routes: [{
              name: "home"
            }]
        });
      navigationRef.dispatch(resetAction)
      // alert("signed in fuck")
    } else {

      // alert("signed out fuck")
      console.log("signed out")
      // user signed out
      userStore.setUser("")
      const resetAction = CommonActions.reset({
          index: 0,
          routes: [{
              name: "login"
            }]
        });
      navigationRef.dispatch(resetAction)
    }
  });  

  },[])

  

  const registerForPushNotificationsAsync = async () => {
    try{
      if (Constants.isDevice) {
        // console.log(Constants.manifest)
        // alert(Constants.manifest.id)
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          alert('Failed to get push token for push notification!');
          return;
        }
        const token = (await Notifications.getExpoPushTokenAsync()).data;
        // alert(token);
        setExpoPushToken( token );
      } else {
        alert('Must use physical device for Push Notifications');
      }
    } catch(err) {
      alert(err)
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    };  



  const AppStack = () => {
    return (
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName="home"
      >
        <Stack.Screen name="login" component={LoginScreen} />
        <Stack.Screen name="signup" component={SignupScreen} />
        <Stack.Screen name="home" component={HomeScreen} />
        <Stack.Screen name="chat" component={ChatScreen} />
        <Stack.Screen name="chatList" component={ChatListScreen} />
        <Stack.Screen name="robotConfirmation" component={RobotConfirmationScreen} />
        <Stack.Screen name="dashboard" component={DashboardScreen} />
        <Stack.Screen name="userChat" component={UserChatScreen} />

      </Stack.Navigator>
    )
  }


  const colorScheme = useColorScheme()  

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <AppStack />
    </NavigationContainer>
  )
}

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["login","main"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
