import React from "react"
import { useState, useEffect, useRef } from 'react'
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TouchableOpacity, Dimensions } from "react-native"
import { Screen, Text, TextField, Button, Header, Menu } from "../../components"
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import { useStores } from "../../models"
import { color } from "../../theme"
import { getSnapshot, onSnapshot } from "mobx-state-tree"
import firebase from "../../components/firebase/firebase"

// import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import SideMenu from 'react-native-side-menu-updated'



const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  flexDirection: "column",
  justifyContent: "center"

}
const ROOT1: ViewStyle = {
  backgroundColor: color.palette.black,
  // flex: 1,
  // justifyContent: "flex-start",
  
  zIndex:1,

}
const ROOT2: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  
  // transform: [{translateY : 5}]

  // height: 
}

const HEADER: ViewStyle = {
  textAlign: "center"
}
const HEADER1: ViewStyle = {
  textAlign: "center",
  fontSize: 35,
  paddingVertical:20,

}
const BUTTON: TextStyle = {
  fontSize: 15,
}
const BUTTON1: ViewStyle = {
  backgroundColor: color.palette.lightBlue,
  marginTop: 20,
  paddingVertical: 15,
  width: "95%",
  maxWidth: 400,
  justifyContent: "center",

}
const INPUT: TextStyle = {
  width: "95%",
  maxWidth: 400,
  justifyContent: "center",
}
const TEXT: ViewStyle = {
  fontSize: 15,
  color: color.palette.lightBlue,
  marginTop: 20,
  paddingVertical: 15,
  textAlign: "center",
}


export const LoginScreen = observer(function LoginScreen(props) {
  // Pull in one of our MST stores
  const { loginStore, userStore, navStore } = useStores()
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const [height,setHeight] = useState(300)


  const { username, password, setUsername, setPassword } = loginStore
  
  // const auth = getAuth()



  // Pull in navigation via hook
  const navigation = useNavigation()

  const { canGoBack, focus } =navStore;
  const isFocused = useIsFocused();
 
  useEffect(()=>{
    if(isFocused){
      navStore.setCanGoBack(navigation.canGoBack())
      navStore.setFocus('login')
    }
    // if(userStore.getUser() != ''){
    //   const resetAction = CommonActions.reset({
    //       index: 0,
    //       routes: [{
    //           name: "home"
    //         }]
    //     });
    //     navigation.dispatch(resetAction);
    // }
  },[isFocused])

  // onSnapshot(userStore, (newSnapshot) => {
  //   console.info("Got new snapshot:", newSnapshot)
  // })

  const handleLogin = () => {
    // alert('signing in '+username+' '+password)

    // need spinning circle

    firebase.auth().signInWithEmailAndPassword(username, password)
      .then(() => {
        // setShowLogin(false)
        // alert('signed in')
      })
      .catch((error) => {
        alert(error)
      });
  };

  useEffect(()=>{
    // alert("signing out")
    // signOut(auth).then(() => {
    //   // Sign-out successful.
    //   alert('signedout')
    //   setUser()
    //   navigation.navigate("login")
    //   }).catch((error) => {
    //   // An error happened.
    //   alert(error)
    // });
  },[])

  useEffect(()=>{
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }
    const windowHeight = Dimensions.get('window').height;
    if((windowHeight*.75)<=300){
     setHeight(windowHeight*.75)
    }
    
  })

  return (
    <SideMenu menu=<Menu navigation={navigation}/> user={userStore} setUser={()=>{userStore.setUser("")}} onChange={(e)=>{setOpen(e)}}  isOpen={open} menuPosition="right" openMenuOffset={width}> 

    <Screen style={ROOT} preset="scroll">
      <View style={ROOT1} >
        <Header
          openMenu={()=>{setOpen(prev=>!prev)}}
          navigation={navigation}
          canGoBack={canGoBack}
          focus={focus}
        />  
        
      </View>
      <View style={ROOT2}>
        <Text preset="header" text="Login" style={HEADER} />
        <TextField placeholder="email" style={INPUT} label="Email" onChangeText={(e)=>{setUsername(e)}}/>
        <TextField placeholder="password" style={INPUT} label="Password" secureTextEntry={true} onChangeText={(e)=>{setPassword(e)}}/>
        <Button text="Submit" style={BUTTON1} textStyle={BUTTON} onPress={handleLogin}/>
        <Text text="Sign Up" style={TEXT} onPress={()=>{navigation.navigate('signup')}}/>
      </View>
    </Screen>
    </SideMenu>
  )
})
