import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */


export const UserModel = types
  .model("User")
  .props({
    user: types.optional(types.string, ""),
    custServRep: types.optional(types.boolean, false),
  })
  .views((self) => ({
    getUser: ()=>{
      return self.user
    },
    getCustServRep: ()=>{
      return self.custServRep
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setUser: (e)=>{
      self.user=e
    },
    setCustServRep: (e)=>{
      self.custServRep=e
    },
}))
type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {})
