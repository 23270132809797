import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { CharacterStoreModel } from "../character-store/character-store"
import { LoginModel } from "../login/login"
import { UserModel } from "../user/user"
import { NavModel } from "../nav/nav"

/**
 * A RootStore model.
 */
// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  // characterStore: types.optional(CharacterStoreModel, {} as any),
  loginStore: types.optional(LoginModel, {} as any),
  userStore: types.optional(UserModel, {} as any),
  navStore: types.optional(NavModel, {} as any),
})

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
