import React, { useEffect, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TouchableOpacity, Dimensions, FlatList, ScrollView } from "react-native"
import { Screen, Text, Header, Menu } from "../../components"
import { useNavigation, useIsFocused } from "@react-navigation/native"
import { useStores } from "../../models"
import { color } from "../../theme"
import SideMenu from 'react-native-side-menu-updated'
import firebase from "../../components/firebase/firebase"



const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  justifyContent:"center",
  alignItems:"center",  
  width: "100%",
}
const ROOT1: ViewStyle = {
  // backgroundColor: color.palette.black,
  flex: 1,
  width: "100%",
  maxWidth: 1200
}
const HEADER: ViewStyle = {
  textAlign: "center"
}
const ITEM: ViewStyle = {
  backgroundColor: color.palette.lightBlue,
  padding: 20,
  marginVertical: 8,
  marginHorizontal: 16,
  borderRadius: 5
}

export const ChatListScreen = observer(function ChatListScreen() {
  const { userStore, navStore } = useStores()
  const { user, custServRep } = userStore
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const navigation = useNavigation()
  const [chats,setChats] = useState([])
  const [removed,setRemoved] = useState([])
  const unsubscribe = useRef(null)

  const db = firebase.firestore();
  const chatRef = db.collection("chats");





  const { canGoBack } =navStore;
  const isFocused = useIsFocused();
 
  useEffect(()=>{
    if(isFocused){
      navStore.setCanGoBack(navigation.canGoBack())
      userStore.setUser(userStore.getUser())
    }
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }

  },[isFocused])

  useEffect(()=>{
    setChats([])
    console.log("chat-list")
    if(unsubscribe.current != null){
      unsubscribe.current()
    }
    if(user != ""){
            console.log("chat-list1")
                  // alert(user)
      if(!custServRep){
        // create user chat screen........and navigate to it.....
        // navigation.navigate("userChat")
        // chatRef.doc(user).get().then((doc) => {
        //     if (doc.exists) {
        //         const docData = doc.data()
        //         if(docData.customerService){
        //           userStore.setCustServRep(docData.customerService);
        //         }
        //     } else {
        //         // doc.data() will be undefined in this case
        //         console.log("No such document!");
        //     }
        // }).catch((error) => {
        //     console.log("Error getting document:", error);
        // });
      } else{

      unsubscribe.current = chatRef.where("needsResponse", "==" , true)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            console.log("chat-list2")
                  console.log(change.doc.id);
              if (change.type === "added") {
                  console.log("added : ", change.doc.data());
                  // alert(change.doc.id)
                  const data = change.doc.data();
                  data['id'] = change.doc.id;
                  setChats(prev=>[...prev, data])

              }
              if (change.type === "modified") {
                  console.log("modified : ", change.doc.data());
                  
              }
              if (change.type === "removed") {
                setRemoved(prev=>[...prev, change.doc.data()])
              }
          });
      }, (error) => {
        console.log(error)
    });
      return ()=>{unsubscribe.current()}
      }
    }

  },[isFocused])

  useEffect(()=>{
    if(removed.length>0){
      // search chats and remove from array
      const newChats = [];
      removed.forEach((remove)=>{
        chats.forEach((item, index)=>{
          console.log(item.chat[0].id)
          console.log(remove.chat[0].id)
          if(item.chat[0].id != remove.chat[0].id){
            newChats.push(item)
          }
        })
      })
      setChats(newChats)
      setRemoved([])
    }
  },[removed])

  // useEffect(()=>{
  //   if(unsubscribe.current != null){
  //     // console.log(unsubscribe)
  //   }
  // },[unsubscribe])

  const handlePress = (title) =>{
    navigation.navigate('chat', {chatId:title})
  } 

  const Item = ({ title, nav }) => (
    <View style={ITEM}>
      <TouchableOpacity onPress={()=>{handlePress(title)}}>
        <Text style={{fontSize:25, textAlign:"center"}}>{title}</Text>
      </TouchableOpacity>
    </View>
  );

  const renderItem = ({ item }) => (
      <Item title={item.id} nav={item} />
    );



  // Pull in navigation via hook
  
  return (

    <SideMenu menu=<Menu navigation={navigation}/> onChange={(e)=>{setOpen(e)}}  isOpen={open} menuPosition="right" openMenuOffset={width}> 
      <Screen style={ROOT} preset="fixed">
        <ScrollView style={ROOT1} >
          <View >
            <Header
              openMenu={()=>{setOpen(prev=>!prev)}}
              navigation={navigation}
              canGoBack={canGoBack}

            />  
          </View>
          <FlatList
            data={chats}
            renderItem={renderItem}
            keyExtractor={item => item.id}
          />
          {chats.length == 0 &&
            <Text preset="header" text="No chats need a reply" style={HEADER} />
          }
        </ScrollView>
      </Screen>
    </SideMenu>
  )
})
