import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TouchableOpacity, Dimensions } from "react-native"
import { Screen, Text, Header, Menu } from "../../components"
import { useNavigation, useIsFocused } from "@react-navigation/native"
import { useStores } from "../../models"
import { color } from "../../theme"
import SideMenu from 'react-native-side-menu-updated'


const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  justifyContent:"center",
  alignItems:"center",  
  width: "100%",
}
const ROOT1: ViewStyle = {
  // backgroundColor: color.palette.black,
  flex: 1,
  width: "100%",
  maxWidth: 1200
}

export const RobotConfirmationScreen = observer(function RobotConfirmationScreen() {
  const { userStore, navStore } = useStores()
  const { user } = userStore
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const navigation = useNavigation()

  const { canGoBack } =navStore;
  const isFocused = useIsFocused();
 
  useEffect(()=>{
    if(isFocused){
      navStore.setCanGoBack(navigation.canGoBack())
      userStore.setUser(userStore.getUser())
    }

  },[isFocused])

  useEffect(()=>{
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }
  },[])

  useEffect(()=>{
    console.log(user)
  },[])

  



  // Pull in navigation via hook
  
  return (

    <SideMenu menu=<Menu navigation={navigation}/> user={user} setUser={()=>{userStore.setUser("")}} onChange={(e)=>{setOpen(e)}}  isOpen={open} menuPosition="right" openMenuOffset={width}> 
      <Screen style={ROOT} preset="fixed">
        <View style={ROOT1} >
          <View >
            <Header
              openMenu={()=>{setOpen(prev=>!prev)}}
              navigation={navigation}
              canGoBack={canGoBack}

            />  
          </View>
          <View preset="scroll">
            <Text preset="header" text="Robot Confirmation" />
          </View>
        </View>
      </Screen>
    </SideMenu>
  )
})
