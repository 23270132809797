import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * Model description here for TypeScript hints.
 */
export const NavModel = types
  .model("Nav")
  .props({
    canGoBack: types.optional(types.boolean, true),
    focus: types.optional(types.string, "login"),
  })
  .views((self) => ({
    getcanGoBack: ()=>{
      return self.canGoBack
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    setCanGoBack: (e)=>{
      self.canGoBack=e
    },
    setFocus: (e)=>{
      self.focus=e
    },
    
}))
type NavType = Instance<typeof NavModel>
export interface Nav extends NavType {}
type NavSnapshotType = SnapshotOut<typeof NavModel>
export interface NavSnapshot extends NavSnapshotType {}
export const createNavDefaultModel = () => types.optional(NavModel, {})
