import React, { useEffect, useState, useRef } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TouchableOpacity, Dimensions, FlatList, ScrollView, Keyboard } from "react-native"
import { Screen, Text, Header, Menu, TextField } from "../../components"
import { useNavigation, useIsFocused } from "@react-navigation/native"
import { useStores } from "../../models"
import { color } from "../../theme"
import SideMenu from 'react-native-side-menu-updated'
import firebase from "../../components/firebase/firebase"
import { Icon } from "../../components/icon/icon"




const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  justifyContent:"center",
  alignItems:"center",
  width: "100%",
}
const ROOT1: ViewStyle = {
  // backgroundColor: color.palette.black,
  flex: 1,
  width: "100%",
  maxWidth: 1200,
  // justifyContent: "center",
  // alignItems: "center"
  // borderColor: 'red',
  // borderWidth:1,
}
const ITEM: ViewStyle = {
  backgroundColor: color.palette.lightBlue,
  padding: 20,
  marginVertical: 8,
  marginHorizontal: 16,
  borderRadius: 5
}
const CONTAINER: ViewStyle = {
  flex:1,
  flexDirection: 'row',
  // alignItems:'flex-end'
  // justifyContent: 'flex-end',
}
const CONTAINER1: ViewStyle = {
  
  flex:3,  

}
const SPACE: ViewStyle ={
  flex:1,
}
const USER: ViewStyle = {
  
  // justifyContent: 'flex-start',
  alignSelf: 'flex-start',
  backgroundColor: color.palette.lightBlue,
  borderRadius: 5,
  padding: 3,
  // width: "75%",
  // maxWidth: 400,

}
const USER1: ViewStyle = {
  alignSelf: 'flex-start',
  // flex:3,
  backgroundColor: color.palette.angry,
  borderRadius: 5,
  padding: 3,
}
const USER2: ViewStyle = {
  alignSelf: 'flex-start',
  // flex:3,
  backgroundColor: color.palette.orange,
  borderRadius: 5,
  padding: 3,
}


const BOT: ViewStyle ={
  flex:3,
  // justifyContent: 'flex-end',
  alignSelf: 'flex-end',
  // justifyContent: "right",
  backgroundColor: color.palette.forrestGreen,
  borderRadius: 5,
  // width: "75%",
  // maxWidth: 400,
  padding: 3,
}
const ITEMTEXT: TextStyle ={
  // alignSelf: 'flex-start',
  fontSize:25,
  padding: 5, 

  // textAlign:"left"
}
const ITEMTEXT1: TextStyle ={
  // alignSelf: 'flex-end',
  padding: 5, 
  fontSize:25, 
  // textAlign:"right"
}
const INPUT: TextStyle = {
  width: "90%",
  // maxWidth: 400,
  // flex:1,
  paddingHorizontal: 5,
  paddingVertical: 2,
  textAlign: "center",

}




export const UserChatScreen = observer(function ChatScreen(props) {
  const { userStore, navStore } = useStores()
  const { user } = userStore
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const [reply,setReply] = useState("")
  const navigation = useNavigation()

  const { canGoBack } =navStore;
  const isFocused = useIsFocused();
  const [chats,setChats] = useState(["|"])
  const unsubscribe = useRef(null)
  const input = useRef(null)
 
  useEffect(()=>{
    if(isFocused){
      navStore.setCanGoBack(navigation.canGoBack())
      userStore.setUser(userStore.getUser())
    }

  },[isFocused])

  useEffect(()=>{
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }
  },[])

  useEffect(()=>{
    console.log(props)
  },[])

  const db = firebase.firestore();
  
  useEffect(()=>{
    console.log("chat-list")
    if(unsubscribe.current != null){
      unsubscribe.current()
    }
    if(user != ""){
  const chatRef = db.collection("chats").doc(user);
            console.log("chat-list1")
                  // alert(user)
      unsubscribe.current = chatRef
        .onSnapshot((doc) => {

          const data = doc.data();
          console.log(data)
          const chatData =  data.chat
          setChats(chatData)

      }, (error) => {
        console.log(error)
    });
      return ()=>{unsubscribe.current()}
    }

  },[props])
   
  function uuidv4() {
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const handleKeyPress = () =>{
    Keyboard.dismiss()
    handleReply()
  }
  const handleEnter = (e) =>{
    if(e=="enter"){
      handleReply()
    }
    
  }
  const handleReply = () =>{
    // connect responses
     if(reply == ""){
      alert("message can not be empty")
     } else{

      const chatRef = db.collection("chats").doc(user);
      const newId = uuidv4()
      const chatSend = [];

      let needsResponse = false;

      chats.forEach((item, index)=>{
        chatSend.push(item);
      })
      chatSend.push({
        id: newId,
        message: reply,
        responded: false,
        seen: false,
        user: user,
        notified: false,
      })
      console.log(chatSend)

      chatRef.set({
        chat: chatSend,
        needsResponse: true
      }, { merge: true })
      setReply("")
      input.current.clear()
     }

  } 

  const Item = ({ message, id, responded, user }) => (
    
    <View style={{flex: 1}}>
      { user == 'bot' ? 
      <View style={CONTAINER}>
        <View style={SPACE}>
        </View>
        <View style={CONTAINER1}>
          <View style={BOT}>
              <Text style={ITEMTEXT1}>{message}</Text>
          </View>
        </View>
      </View>
    :
    <View>
      <View style={CONTAINER}>
        <View style={CONTAINER1}>
          <View style={USER}>
            <TouchableOpacity>
                <Text style={ITEMTEXT}>{message}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={SPACE}>
        </View>
      </View>
    </View>
      }
    </View>
  );

  const renderItem = ({ item }) => (
      <Item message={item.message} id={item.id} responded={item.responded} user={item.user} />
    );
  


  
  return (

    <SideMenu menu=<Menu navigation={navigation}/> user={user} setUser={()=>{userStore.setUser("")}} onChange={(e)=>{setOpen(e)}}  isOpen={open} menuPosition="right" openMenuOffset={width}> 
      <Screen style={ROOT} preset="scroll">
          <View style={{width:"100%", maxWidth: 1200}}>
            <Header
              openMenu={()=>{setOpen(prev=>!prev)}}
              navigation={navigation}
              canGoBack={canGoBack}

            />  
          </View>
        <ScrollView style={ROOT1} >
          <FlatList
            data={chats}
            renderItem={renderItem}
            keyExtractor={item => item.id}
          />
        </ScrollView>
          <View style={{width:"100%", maxWidth: 1200, flexDirection:"row", alignItems: "center"}}>
            <TextField forwardedRef={input} placeholder="reply" style={INPUT} onKeyPress={handleEnter} onSubmitEditing={handleKeyPress} onChangeText={(e)=>{setReply(e)}}/>
           
              <View style={{justifyContent: "center"}}>
                <TouchableOpacity style={{padding: 5, textAlign: "center"}} onPress={handleReply}>
                  <Icon icon="enter"/>
                </TouchableOpacity>
              </View>
            
          </View>
      </Screen>
    </SideMenu>
  )
})
