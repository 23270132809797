import React from "react"
import { useState, useEffect, useRef } from 'react'
import { observer } from "mobx-react-lite"
import { ViewStyle, View, TouchableOpacity, Dimensions } from "react-native"
import { Screen, Text, TextField, Button, Header, Menu } from "../../components"
import { useNavigation, useIsFocused, CommonActions } from "@react-navigation/native"
import { useStores } from "../../models"
import { color } from "../../theme"
import { getSnapshot, onSnapshot } from "mobx-state-tree"
import firebase from "../../components/firebase/firebase"

// import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import SideMenu from 'react-native-side-menu-updated'



const ROOT: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-evenly"

}
const ROOT1: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 1,
  justifyContent: "flex-start"

}
const ROOT2: ViewStyle = {
  backgroundColor: color.palette.black,
  flex: 7,
  // flex: 1,
  justifyContent: "center",
  alignItems: "center",
}

const HEADER: ViewStyle = {
  textAlign: "center"
}
const HEADER1: ViewStyle = {
  textAlign: "center",
  fontSize: 35,
  paddingVertical:20,

}
const BUTTON: TextStyle = {
  fontSize: 15,
}
const BUTTON1: ViewStyle = {
  backgroundColor: color.palette.lightBlue,
  marginTop: 20,
  paddingVertical: 15,
  width: "95%",
  maxWidth: 400,
  justifyContent: "center",

}
const INPUT: TextStyle = {
  width: "95%",
  maxWidth: 400,
  justifyContent: "center",
}
const TEXT: ViewStyle = {
  fontSize: 15,
  color: color.palette.lightBlue,
  marginTop: 20,
  paddingVertical: 15,
  textAlign: "center",
}


export const SignupScreen = observer(function SignupScreen(props) {
  // Pull in one of our MST stores
  const { userStore, navStore } = useStores()
  const [open,setOpen] = useState(false)
  const [width,setWidth] = useState(300)
  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")
  const [passwordConfirmation,setPasswordConfirmation] = useState("")


  // const { username, password, setUsername, setPassword } = loginStore
  
  // const auth = getAuth()



  // Pull in navigation via hook
  const navigation = useNavigation()

  const { canGoBack, focus } =navStore;
  const isFocused = useIsFocused();
 
  useEffect(()=>{
    if(isFocused){
      navStore.setCanGoBack(navigation.canGoBack())
      navStore.setFocus('signup')
    }
    if(userStore.getUser() != ''){
      const resetAction = CommonActions.reset({
          index: 0,
          routes: [{
              name: "home"
            }]
        });
        navigation.dispatch(resetAction);
    }
  },[isFocused])

  // onSnapshot(userStore, (newSnapshot) => {
  //   console.info("Got new snapshot:", newSnapshot)
  // })
  const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  const handleSignup = () => {
    // alert('signing up'+username+' '+password+' '+passwordConfirmation)

    if(validateEmail(username)){

      if(password == passwordConfirmation){
        if(password.length >=8){


        firebase.auth().createUserWithEmailAndPassword(username, password)
          .then(() => {
            // setShowLogin(false)
            alert('signed up')
          })
          .catch((error) => {
            alert(error)
          });
        }else{
          alert("Password must be longer then or equal to 8 characters")

        }
      } else{
        alert("Password must match password confirmation")

      }
    } else{
      alert("Email not formated correctly")
    }
  };

  useEffect(()=>{
    // alert("signing out")
    // signOut(auth).then(() => {
    //   // Sign-out successful.
    //   alert('signedout')
    //   setUser()
    //   navigation.navigate("login")
    //   }).catch((error) => {
    //   // An error happened.
    //   alert(error)
    // });
  },[])

  useEffect(()=>{
    const windowWidth = Dimensions.get('window').width;
    if((windowWidth*.75)<=300){
     setWidth(windowWidth*.75)
    }
  })

  return (
    <SideMenu menu=<Menu navigation={navigation}/> user={userStore} setUser={()=>{userStore.setUser("")}} onChange={(e)=>{setOpen(e)}}  isOpen={open} menuPosition="right" openMenuOffset={width}> 

    <Screen style={ROOT} preset="scroll">
      <View style={ROOT1} >
        <Header
          openMenu={()=>{setOpen(prev=>!prev)}}
          navigation={navigation}
          canGoBack={canGoBack}
          focus={focus}
        />  
        
      </View>
      <View style={ROOT2}>
        <Text preset="header" text="Sign Up" style={HEADER} />
        <TextField placeholder="email" style={INPUT} label="Email" onChangeText={(e)=>{setUsername(e)}}/>
        <TextField placeholder="password" style={INPUT} label="Password" secureTextEntry={true} onChangeText={(e)=>{setPassword(e)}}/>
        <TextField placeholder="password confirmation" style={INPUT} label="Password Confirmation" secureTextEntry={true} onChangeText={(e)=>{setPasswordConfirmation(e)}}/>
        <Button text="Submit" style={BUTTON1} textStyle={BUTTON} onPress={handleSignup}/>
      </View>
    </Screen>
    </SideMenu>
  )
})
