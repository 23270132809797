import * as fire from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import 'firebase/messaging';




// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyACKWQN-BNiZDZgM9wHoGQyin0O6RO9wGo",
  authDomain: "consultajent.firebaseapp.com",
  projectId: "consultajent",
  storageBucket: "consultajent.appspot.com",
  messagingSenderId: "364016585304",
  appId: "1:364016585304:web:fcbb57e1a5d31e83e6982d",
  measurementId: "G-BKM2ZKY98Z"
};

// const Firebase = initializeApp(firebaseConfig);
let firebase;

if (fire.apps.length === 0) {
  firebase = fire.initializeApp(firebaseConfig, {
  // @ts-ignore
  useFetchStreams: false,
  cacheSizeBytes: 100000000,
});
  firebase.firestore().settings({experimentalForceLongPolling: true}); // add this

}
// if (!firebase.apps.length) {
//  initializeApp(firebaseConfig);
// }else {
//    firebase.app(); // if already initialized, use that one
// }

export default firebase